exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bjj-muenchen-js": () => import("./../../../src/pages/bjj-muenchen.js" /* webpackChunkName: "component---src-pages-bjj-muenchen-js" */),
  "component---src-pages-bjj-probetraining-js": () => import("./../../../src/pages/bjj-probetraining.js" /* webpackChunkName: "component---src-pages-bjj-probetraining-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-firmen-yoga-in-muenchen-js": () => import("./../../../src/pages/firmen-yoga-in-muenchen.js" /* webpackChunkName: "component---src-pages-firmen-yoga-in-muenchen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kampfsport-in-muenchen-js": () => import("./../../../src/pages/kampfsport-in-muenchen.js" /* webpackChunkName: "component---src-pages-kampfsport-in-muenchen-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-mma-kickboxen-muenchen-js": () => import("./../../../src/pages/mma-kickboxen-muenchen.js" /* webpackChunkName: "component---src-pages-mma-kickboxen-muenchen-js" */),
  "component---src-pages-ninja-probetraining-js": () => import("./../../../src/pages/ninja-probetraining.js" /* webpackChunkName: "component---src-pages-ninja-probetraining-js" */),
  "component---src-pages-probetraining-js": () => import("./../../../src/pages/probetraining.js" /* webpackChunkName: "component---src-pages-probetraining-js" */),
  "component---src-pages-stundenplan-js": () => import("./../../../src/pages/stundenplan.js" /* webpackChunkName: "component---src-pages-stundenplan-js" */),
  "component---src-pages-trauma-informed-yoga-und-selbstverteidigung-js": () => import("./../../../src/pages/trauma-informed-yoga-und-selbstverteidigung.js" /* webpackChunkName: "component---src-pages-trauma-informed-yoga-und-selbstverteidigung-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-yoga-covid-erholung-js": () => import("./../../../src/pages/yoga-covid-erholung.js" /* webpackChunkName: "component---src-pages-yoga-covid-erholung-js" */),
  "component---src-pages-yoga-in-kindergarten-und-schulen-in-munchen-js": () => import("./../../../src/pages/yoga-in-kindergarten-und-schulen-in-munchen.js" /* webpackChunkName: "component---src-pages-yoga-in-kindergarten-und-schulen-in-munchen-js" */),
  "component---src-pages-yoga-muenchen-js": () => import("./../../../src/pages/yoga-muenchen.js" /* webpackChunkName: "component---src-pages-yoga-muenchen-js" */),
  "component---src-templates-blog-pages-js": () => import("./../../../src/templates/blog-pages.js" /* webpackChunkName: "component---src-templates-blog-pages-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

