import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import globals from "./globals"
import { Container, ContentBox, H3 } from "./styledcomponents"
import Button from "./button"

import { cardList } from "./schedule-cardlist"

import Icons from "./icons"

const Icon = styled(Icons)`
  color: ${globals.color.lightShades};
  height: 1em;
  margin-right: 0.5em;
  overflow: visible;
`

const CardDiv = styled.div`
  text-align: left;
  width: 90% !important;
  min-width: 300px;
  max-width: 450px;
  min-height: 650px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  justify-content: center;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: white;
  margin: 30px 15px 30px 15px;
  border-radius: 10px;
`

const TextBox = styled(ContentBox)`
  flex-grow: 1;
`

const TopicH = styled(H3)`
  &&:after {
  ${(props) =>
    props.noLine
      ? undefined
      : `
  display: block;
  content: '';
  border: solid ${globals.color.main};
  width: 3em;
  margin-top: 0.3em;
 `}
`

function Card(props) {
  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: () => setUpdateCount(updateCount + 1),
    beforeChange: (current, next) => setSlideIndex(next)
  }

  let sliderRef = useRef(null);

  const [slideIndex, setSlideIndex] = useState(7);
  const [updateCount, setUpdateCount] = useState(0);

  const cards = cardList

  const onChange = (value) => {
    setSlideIndex(value)
  }

  useEffect(() => {
    setSlideIndex(props.value)
    sliderRef.slickGoTo(props.value)
  }, [props.value])

  const files = useStaticQuery(graphql`
    query files {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(JPG)|(JPEG)|(webp)|(jpeg)/" }
          dir: { regex: "/(Schedule)/" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <div className="slider-container" margin="2em 0">
      <h2>Unser Kursangebot</h2>
      <Slider
        {...settings}
        ref={slider => {
          sliderRef = slider;
        }}
      >
        {cards.map(({ id, name, img, text }) => (
          <CardDiv key={id}>
            {img && (
              <GatsbyImage
                image={
                  files.allFile.edges.filter(
                    (edge) => edge.node.base === img
                  )[0].node.childImageSharp.gatsbyImageData
                }
                style={{ borderRadius: "10px 10px 0 0" }}
                alt={name}
              />
            )}
            <TextBox margin="0" padding="0 1em">
              <TopicH>{name}</TopicH>
              <div className="cardText">{text}</div>
            </TextBox>
            <Button
              width="70%"
              margin="0 0 1.5em 0"
              to="https://app.team-laurien-alex.de/"
            >
              Stunde Buchen
            </Button>
          </CardDiv>
        ))}
      </Slider>
    </div>
  )
}

export default Card;