import React from "react"
import Link from "gatsby-link"

import { Container, P } from "./styledcomponents"
import globals from "./globals"
import styled from "styled-components"

const LinkW = styled(Link)`
  color: white;
  &:hover {
    color: ${globals.color.main};
  }
`
function Announcement(props) {
  return (
    <Container
      alignItems="left"
      padding="0"
      backgroundColor={globals.color.darkAccent}
    >
      <P left padding="0.5em 2em" color="white" style={{ fontSize: "1em" }}>
        <strong>
          <LinkW to="https://shop.team-laurien-alex.de/product/bjj-fundamentals-kurs">
            🤼 Neuer BJJ Fundamentls Kurs: jetzt durchstarten!
          </LinkW>
          &nbsp; &nbsp;
        </strong>
      </P>
    </Container>
  )
}

export default Announcement
