import React from "react"

const ninjaText = (
  <>
    <p>Die ‚Kleinen Ninjas‘ fokussieren sich auf:</p>
    <ul>
      <li>Spielerische Entwicklung der motorischen Fähigkeiten</li>
      <li>Steigerung von Selbstbehauptung und Selbstvertrauen</li>
      <li>Fördern Werte wie Fairen Umgang, Offenheit, Kreativität & Optimismus</li>
      <li>Spaß steht an vorderster Stelle</li>
    </ul>
  </>
)

const bjjKidsText = (
  <>
    <p>BJJ für Kinder: </p>
    <ul>
      <li>
        BJJ (Brazilian Jiu Jitus) ein effektiver Kampfsport der ohne Schläge und Tritte auskommt
      </li>
      <li>Setzt Mobbing ein Ende</li>
      <li>Bietet die Möglichkeit an Wettkämpfen teilzunehmen</li>
    </ul>
  </>
)

const bjjYouthText = (
  <>
    <p>Vollkontakt kämfen mit geringen Verletzungsrisiko? Dann bist du beim Brazillian Jiu Jitsu (BJJ) genau richtig!
    </p>
    <ul>

      <li>Fordernder Kampfsport mit Fokus auf Würfen, Kontrolltechniken und Submissions</li>
      <li>Bietet die Möglichkeit sich bie Wettkämpfen mit anderen zu messen</li>
      <li>Einsteigerfreundlich, wir helfen dir von Beginn an</li>
    </ul>
  </>
)

const kickboxenKidsText = (
  <>
    <p>Beim Kickboxen lernen die Kids:</p>
    <ul>
      <li>auf Spielerische Weise Disziplin und Konzentration</li>
      <li>eine bessere Körperhaltung</li>
      <li>fairen Umgang mit anderen</li>
    </ul>
  </>
)

const bjjErwachseneText = (
  <>
    <p>
      Gi BJJ ist Submission Grappling mit Anzug (Kimono). Der Fokus liegt auf: Würfen, Passen, Pinnen und Submitten. Die im Moment effektivste Lernmethode ist der Constraint Led Approach (CLA), bei dem du durch task-based Games selbst herausfindest, wie man beispielsweise jemanden so kontrolliert, dass er aufgeben (tap) muss.

    </p>
    <ul>
      <li>Baue Stress ab & powere dich aus in einem sicheren und freundlichen Team
      </li>
      <li>Lerne am effektivsten durch Spaß und problemlösendes Denken
      </li>
      <li>Verbessere deine körperliche und mentale Fitness
      </li>
    </ul>
  </>
)

const nogiErwachseneText = (
  <>
    <p>
      BJJ ohne Gi (NoGi) ist ein schnellerer Submission Grappling Stil, da man weniger Griffe hat als beim Gi Training. Der Fokus liegt jedoch auf demselben: Würfe, Passen, Pinnen & Submitten. Bei NoGi sind mehrere Beinhebel erlaubt.
    </p>
    <ul>
      <li>Baue Stress ab, powere dich aus in einer sicheren und freundlichen Atmosphäre
      </li>
      <li>Lerne am effektivsten durch die Constant Led Approach (CLA) Methode</li>
      <li>Verbessere deine körperliche & mentale Fitness</li>
    </ul>
  </>
)

const kickboxenErwachseneText = (
  <>
    <p>
      MMA ist die Kunst, Striking und Grappling Stile in ein effektives System
      zu integrieren:
    </p>
    <ul>
      <li>Striking, also z.B.: Boxen, Kickboxen, Muay Thai</li>
      <li>Würfe und Takedowns aus Judo und Wresting</li>
      <li>Kontrolle auf dem Boden, also BJJ und Submission Grappling</li>
    </ul>
  </>
)
const boxsacktrainingText = (
  <>
    <p>
      Beim Boxsacktraining kannst du dich so richtig auspowern:
    </p>
    <ul>
      <li>Schlag- & Tritttechniken mit Power lernen</li>
      <li>Kein physicher Kontakt mit anderen Teilnehmern</li>
      <li>Kraft, Kondition & Koordination</li>
    </ul>
  </>
)

const performanceTrainingText = (
  <>
    <p>
      Neben Skill Acquisition bieten wir auch Performance Training an. Performance Training fokussiert sich auf Wettkampf und repräsentative Sparring Spiele je nach Regelwerk.
    </p>
    <ul>
      <li>Intensive Einheiten, um Deine Ausdauer, Kraft und Geschwindigkeit zu verbessern</li>
      <li>Spezifisches Szenario-Training, um Dein strategisches Denken zu schärfen</li>
      <li>Individuelle Vorbereitung durch unsere erfahrenen Coaches</li>
    </ul></>
)
const selbstverteidigungFrauenText = (
  <>
    <p>
      Hilft Frauen (ab 14) gefährliche Situationen (in der Schule, auf der Arbeit, daheim und auf der Straße) zu erkennen, vermeiden oder wenn nötig verbal und körperlich effektiv darauf zu reagieren.
    </p>
    <ul>
      <li>Trauma Informierten Ansatz </li>
      <li>Fokus liegt auf Problemen lösen durch Spiele
      </li>
      <li>Mentale Training: wie reagiere ich denn überhaupt in stressigen Situationen?
      </li>
      <li>Erhöht Körperliche Fitness
      </li>
      <li>Spaß & Community
      </li>
    </ul>
  </>
)
const sanfterFlowText = (
  <>
    <p>
      Sanft und langsam öffnen unseren Körper und Geist zu durch sanfte
      Haltungen (Asana) und Atemübungen (Pranayama).
    </p>
    <ul>
      <li>Für alle Level</li>
      <li>Intensität: gering – mittel</li>
      <li>Ashtanga / Iyengar Mischung</li>
    </ul>
  </>
)

const dynamischerFlowText = (
  <>
    <p>
      Diese Yogastunde ist ein aktiver und dynamischer Mix zwischen Ashtanga
      Vinyasa und Iyengar Yoga.
    </p>
    <ul>
      <li>Für Fortgeschrittene</li>
      <li>Intensität: mittel – hoch</li>
      <li>Ashtanga / Iyengar Mischung</li>
    </ul>
  </>
)

const yinText = (
  <>
    <p>
      Mit diesem Yogastil stimulieren wir die Struktur unseres Körpers, also
      tief liegendes Bindegewebe, Faszien, Bänder und Sehnen.
    </p>
    <ul>
      <li>Für alle Level</li>
      <li>Intensität: gering</li>
      <li>3 bis 5 Minuten in einer Körperhaltung</li>
    </ul>
  </>
)

const ashtangaText = (
  <>
    <p>
      Sanft und langsam öffnen unseren Körper und Geist zu durch sanfte
      Haltungen (Asana) und Atemübungen (Pranayama).
    </p>
    <ul>
      <li>Für alle Level</li>
      <li>Intensität: gering – mittel</li>
      <li>Ashtanga / Iyengar Mischung</li>
    </ul>
  </>
)

const familienYogaText = (
  <>
    <p>
      Starte dein Wochenende entspannt und lächelnd mit unserer
      Familien-Yogastunde am Samstag.
    </p>
    <ul>
      <li>Für die ganze Familie</li>
      <li>Während Sommermonaten bei schönem Wetter am Fasaneriesee</li>
      <li>Acro-, Ashtanga- und Iyengaryoga</li>
    </ul>
  </>
)


export const cardList = [
  {
    name: "Kleine Ninjas - Kampfsport für 3 - 5 jährige Kinder",
    img: "Kleine-Ninjas-Kampfsport-Muenchen.jpg",
    text: ninjaText,
    id: 0,
    book: "littleNinjas",
  },
  {
    name: "BJJ für Kinder",
    text: bjjKidsText,
    img: "BJJ-Kids.jpg",
    id: 1,
    book: "bjjKids",
  },
  {
    name: "Kickboxen für Kinder",
    img: "Kickboxen-Kinder-muay-thai-meunchen.jpeg",
    text: kickboxenKidsText,
    id: 2,
    book: "mmaKids",
  },
  {
    name: "BJJ für Jugendliche",
    img: "BJJ-Jugend-wettkampf.jpeg",
    text: bjjYouthText,
    id: 3,
    book: "bjj",
  },
  {
    name: "BJJ für Erwachsene",
    img: "BJJ-Muenchen-Erwachsene.jpg",
    text: bjjErwachseneText,
    id: 4,
    book: "bjj",
  },
  {
    name: "NoGi BJJ für Erwachsene",
    img: "submission-wrestling-nogi-bjj-münchen.JPG",
    text: nogiErwachseneText,
    id: 5,
    book: "nogi",
  },
  {
    name: "BJJ Performace Training",
    img: "BJJ-Performance-Training-München.JPG",
    text: performanceTrainingText,
    id: 6,
    book: "nogi",
  },
  {
    name: "Selbstverteidigung für Frauen",
    img: "Selbstverteidigung-Frauen-Muenchen.jpeg",
    text: selbstverteidigungFrauenText,
    id: 7,
    book: "mma",
  },
  {
    name: "MMA/ Kickboxen für Jugendliche und Erwachsene",
    img: "Kickboxen-MMA-Muay-Thai.jpg",
    text: kickboxenErwachseneText,
    id: 8,
    book: "mma",
  },
  {
    name: "Boxsacktraining",
    img: "Boxsacktraining-münchen.jpg",
    text: boxsacktrainingText,
    id: 9,
    book: "mma",
  },
  {
    name: "Yoga all Levels",
    img: "Dynamischer-Yoga-Flow.jpg",
    text: dynamischerFlowText,
    id: 10,
    book: "dynamischerFlow",
    bookOnline: "onlineDynamischerFlow",
  },
  // {
  //   name: "Sanfter Yoga Flow in die Woche",
  //   img: "Sanfter-Yoga-Flow-in-die-Woche.jpg",
  //   text: sanfterFlowText,
  //   id: 8,
  //   book: "sanfterFlow",
  //   bookOnline: "onlineSanfterFlow",
  // },
  // {
  //   name: "Yin Yoga",
  //   img: "Yin-Yoga.jpg",
  //   text: yinText,
  //   id: 9,
  //   bookOnline: "onlineYinYoga",
  // },
  // {
  //   name: "Ashtanga",
  //   img: "Ashtanga-für-Anfänger.jpg",
  //   text: ashtangaText,
  //   id: 10,
  //   book: "ashtanga",
  //   bookOnline: "onlineAshtanga",
  // },
  // {
  //   name: "Familien Yoga",
  //   img: "Yoga-Flow-Fortgeschrittene.jpeg",
  //   text: familienYogaText,
  //   id: 11,
  //   book: "familienYoga",
  //   bookOnline: "onlineFamilienYoga",
  // },
]
