import React from "react"
import styled, { css } from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { navigate } from "gatsby" //import navigate from gatsby
import { window } from "browser-monads" // for redirect and thank you message

import globals from "./globals"
import { Container, ContentBox, H2, P } from "./styledcomponents"

import LeaningImage from "./leaningImage"
import Button from "./button"
import Icons from "./icons"

const subColor = globals.color.darkShades
const mainColor = "#666"

const Icon = styled(Icons)`
  background-color: ${globals.color.main}50;
  height: 1.3em;
  margin-right: 5px;
  float: left;
`

const shrinkLabelStyles = css`
  ${(props) =>
    props.name !== "privacy"
      ? `font-size: 12px;  color: ${mainColor};  left: 10px; top: -14px;`
      : undefined}
`

const Form = styled.form`
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridTemplateColumns ? props.gridTemplateColumns : "1fr"};
  grid-gap: 1em 2em;
  width: 100%;
  max-width: ${(props) =>
    props.maxWidth ? props.maxWidth : globals.media.tablet};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.justifyItems && `justify-items: ${props.justifyItems}`};
  @media (max-width: ${globals.media.tablet}) {
    ${(props) =>
    props.gridTemplateColumnsTablet &&
    `grid-template-columns: ${props.gridTemplateColumnsTablet}`};
  }

  @media (max-width: ${globals.media.mobile}) {
    grid-template-columns: 1fr;
  }
`

const GroupContainer = styled.div`
  position: relative;
  padding: 0 10px;
  margin: 0;
  text-align: left;
  ${(props) => props.fullWidth && "grid-column: 1/3"};
  input[type="password"] {
    letter-spacing: 0.3em;
  }
  input[type="checkbox"] {
    position: absolute;
    left: -360%;
    height: 2em;
    width: 400%;
  }

  @media (max-width: ${globals.media.tablet}) {
    ${(props) => props.columnsTablet && `grid-column: ${props.columnsTablet}`};
  }
  @media (max-width: ${globals.media.mobile}) {
    ${(props) => props.fullWidth && "grid-column: 1/2"};
  }
  ${(props) => props.columns && `grid-column: ${props.columns}`}
`

const FormInputContainer = styled.input`
  background: none;
  background-color: white;
  color: ${subColor};
  font-size: 18px;
  padding: 10px;
  display: block;
  width: calc(100% - 20px);
  border: none;
  border-radius: 0;
  background-color: ${globals.color.main}50;
  margin: 0;

  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${(props) => (props.type === "checkbox" ? undefined : shrinkLabelStyles)}
  }
`

const FormTextareaContainer = styled.textarea`
  background: none;
  background-color: white;
  color: ${subColor};
  font-size: 18px;
  padding: 10px;
  display: block;
  width: calc(100% - 20px);
  border: none;
  border-radius: 0;
  background-color: ${globals.color.main}50;
  margin: 0;
  height: 7em;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`

const FormInputLabel = styled.label`
  color: ${subColor};
  font-size: 16px;
  font-weight: normal;
  ${(props) => props.type !== "checkbox" && "position: absolute"};
  pointer-events: none;
  left: ${(props) => (props.type !== "checkbox" ? "20px" : "50px")};
  top: 10px;
  text-align: left;
  transition: 300ms ease all;
  &.shrink {
    ${shrinkLabelStyles}
  }
`

const FormH = styled.p`
  color: ${subColor};
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  pointer-events: none;
  padding-left: 10px;
  margin-bottom: -10px;
`

const GridLeaningImage = styled(LeaningImage)``

const DownloadButton = styled(Button)`
  @media (max-width: ${globals.media.tablet}) {
    grid-row-start: 6;
  }
`

const FormInput = ({ handleChange, label, type, name, id, value, ...props }) => (
  <GroupContainer
    fullWidth={props.fullWidth}
    columns={props.columns}
    columnsTablet={props.columnsTablet}
  >
    {type === "textarea" ? (
      <FormTextareaContainer
        onChange={handleChange}
        name={name}
        id={id}
        {...props}
      />
    ) : (
      <FormInputContainer
        onChange={handleChange}
        type={type}
        name={name}
        id={id}
        {...props}
      />
    )}
    {type === "checkbox" ? (
      <Icon icon={value ? "squareCheck" : "square"} />
    ) : undefined}

    {label ? (
      <FormInputLabel
        className={type !== "checkbox" && value.length ? "shrink" : ""}
        type={type}
        for={name}
      >
        {label}
      </FormInputLabel>
    ) : null}
  </GroupContainer>
)

export class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      result: window.location.hash === "#thankyou" ? "#thankyou" : "",
      ma_local_time: new Date().getTimezoneOffset(),
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      ma_interests: props.referral ? props.referral : "",
      headline: props.headline,
    }
  }

  handleSubmit = (e) => {
    //e.preventDefault()

    addToMailchimp(this.state.ma_email, {
      FNAME: this.state.first_name,
      LNAME: this.state.last_name,
      PHONE: this.state.phone,
      FINDINGUS: this.state.ma_interests,
    }) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        console.log(data)
      })
      .catch((data) => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        console.log("FAIL")
        console.log(data)
      })
  }

  handleChange = (event) => {
    const { value, id } = event.target
    this.setState({ [id]: value })

  }

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  calcInterestGroup = () => {
    const yogaConst = 4
    const maKidsConst = 8
    const maConst = 16
    let activity = 0

    if (this.state.activityYoga) activity += yogaConst
    if (this.state.activityMaKids) activity += maKidsConst
    if (this.state.activityMa) activity += maConst

    return activity
  }

  render() {
    return (
      <Container backgroundColor="white" margin="2em 0">
        <H2>{this.state.headline}</H2>
        {this.state.result ?
          <ContentBox
            backgroundColor={globals.color.lightShades}
            padding="1em"
          >
            <P>Vielen Dank, wir melden uns in Kürze bei dir!</P><
              a onClick={() => this.setState({ result: "" })}>zurück</a>
          </ContentBox>
          :
          <Form
            name="contact"
            action="https://bigin.zoho.eu/crm/WebForm"
            method="POST"
            id="form"
            data-netlify-recaptcha="true"
            data-netlify="true"
            onSubmit={this.handleSubmit}
          >
            <input type='text' style={{ display: "none" }} name='xnQsjsdp' value='af45fb48d6d7b1f8f8a23aa72edc4a2799ddbe7f6f579212a6ebb59bbcf3b5ca' />
            <input type='hidden' name='zc_gad' id='zc_gad' value='' />
            <input type='text' style={{ display: 'none' }} name='xmIwtLD' value='b34bac526041e48acb6998a0cc2be6db8ffd09a403250eaafb9e878ed203957d3d7ad28812854d4d4b11ef6dd05613cf' />
            <input type='text' style={{ display: 'none' }} name='actionType' value='UG90ZW50aWFscw==' />
            <input type='hidden' name='rmsg' id='rmsg' value='false' />
            <input type='text' style={{ display: 'none' }} name='returnURL' value={window.location.href + "#thankyou"} />
            {/* <input type='text' name='First Name' value='Hans' />
            <input type='text' name='Last Name' value='Flammenwerfer' />
            <input fvalidate='true' ftype='email' name='Email' maxlength='100' type='text' value='alex.zurhake@gmx.de' /> */}
            <input name='Potential Name' style={{ display: "none" }} maxlength='120' type='text' value={this.state.first_name + " " + this.state.last_name} class='wf-field-item wf-field-input' />
            <input fvalidate='true' style={{ display: "none" }} ftype='date' type='date' name='Closing Date' maxlength='20' value='' placeholder='9999-12-31' max='9999-12-31' class='wf-field-item wf-field-input wf-field-item-date' onchange='removeError(this);' />

            <select name='Pipeline' style={{ display: "none" }} class='wf-field-item wf-field-dropdown' data-wform-field='select'>
              <option selected value='Sales&#x20;Pipeline&#x20;Standard'>Sales Pipeline Standard</option>
            </select>

            <select name='Stage' style={{ display: "none" }} class='wf-field-item wf-field-dropdown' data-wform-field='select'>
              <option selected value='Qualification'>Qualification</option>
              <option value='Needs&#x20;Analysis'>Needs Analysis</option>
              <option value='Proposal&#x2f;Price&#x20;Quote'>Proposal&#x2f;Price Quote</option>
              <option value='Negotiation&#x2f;Review'>Negotiation&#x2f;Review</option>
              <option value='Closed&#x20;Won'>Closed Won</option>
              <option value='Closed&#x20;Lost'>Closed Lost</option>
            </select>



            <FormInput
              name="First Name"
              type="text"
              value={this.state.first_name}
              onChange={this.handleChange}
              label="Vorname"
              id="first_name"
              required
            />
            <FormInput
              name="Last Name"
              type="text"
              value={this.state.last_name}
              onChange={this.handleChange}
              label="Nachname"
              id="last_name"
              required
            />

            <FormInput
              name="Email"
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              label="Email"
              id="email"
              required
            />
            <FormInput
              name="Phone"
              type="tel"
              value={this.state.phone}
              onChange={this.handleChange}
              label="Telefonnummer"
              id="phone"
              required
            />
            <Button center type="submit">
              Abschicken
            </Button>
          </ Form >
        }
      </Container>
    )
  }
}
