import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentAndPicture from "../components/content-and-picture"
import styled from "styled-components"
import YoutubeBackground from "../components/youtube-background"
import Banner from "../components/banner"
import ValueBox from "../components/valuebox"
import Schedule from "../components/schedule"
import TrustBox from "../components/trusbox"
import IIIStep from "../components/3step"
import Testimonials from "../components/testimonials"
import { ContactForm, Download } from "../components/contactform"
import SideBlocks from "../components/side-blocks"
import globals from "../components/globals"
import { Container, H2, P, Li } from "../components/styledcomponents"

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: max(55vw, 300px);
  width: 100%;
  height: 100%;
  padding: 0;
`

const HeroTextContainer = styled.div`
  max-width: 500px;
  padding: 1em;
  border-radius: 8px;
  position: relative;
  margin: 3em 0 3em 10%;
  background-color: #00000080;
  backdrop-filter: blur(3px);
  @media (max-width: ${globals.media.mobile}) {
    margin-left: auto;
    margin-right: auto;
  }
`

const H1 = styled.h1`
  font-size: 1.6em;
  text-align: left;
  margin-bottom: 0.5em;
  color: ${globals.color.main};
  font-weight: bold;
  @media (max-width: ${globals.media.tablet}) {
    font-size: 1em;
  }
`

const Hr = styled.hr`
  border: solid ${globals.color.main};
  width: 4em;
  margin-left: 0;
  @media (max-width: ${globals.media.tablet}) {
    width: 2.5em;
    2px solid #F17915
  }
`

const Yoga = ({ location, data }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title=" Yoga in München, Feldmoching"
        description="Yoga in München - Felmoching: Verbessere deine Mobilität, Flexibilität, Balance und Kraft. Komm zu einer kostenlosen Probestunde in unser Yogastudio."
      />
      <YoutubeBackground
        videoId={"cqIsxEFajkE"}
        playerOptions={{
          end: 45,
        }}
      >
        <HeroContainer>
          <HeroTextContainer
            style={{
              position: "absolute",
              top: 0,
            }}
          >
            <H1 color="white">Yoga in München</H1> <Hr />
          </HeroTextContainer>
        </HeroContainer>
      </YoutubeBackground>
      <ValueBox
        values={[
          {
            id: 1,
            value: (
              <>
                Flexibilität & Kraft <br />= Mobilität
              </>
            ),
          },
          { id: 2, value: "Aktiviert Dein parasympathische Nervensystem" },
          { id: 3, value: "Verbessert Dein Gleichgewicht" },
          { id: 4, value: "Vermindert Stress & traumabedingte Angstgefühle" },
        ]}
      />

      <ContentAndPicture
        h2="Was ist Yoga?"
        description={
          <P>
            Yoga ist eine Wissenschaft des Lebens, das Selbstverwirklichung oder
            Einheit als ‚Ziel‘ hat. Durch Körperhaltungen (Asana), Atemübungen
            (Pranayama) und Achtsamkeit (Meditation) wird dieses Ziel erreicht.
            Was diese Einheit oder Selbstverwirklichung für Dich bedeuten oder
            aussehen soll, ist komplett an Dich. Wir sind da, um Dich zu
            unterstützen, Dein Ziel zu verwirklichen.
          </P>
        }
      />
      <Banner />
      <ContentAndPicture
        h2="Finde deine ‚Om Dahoam‘ oder bei uns im Studio"
        description={
          <P>
            Wir bieten unsere Yogastunden sowohl in unserem Studio also auch via
            Zoom an. Manchmal ist es gut Yoga in einer Gruppe in einem Studio zu
            machen, manchmal fühlt es sich besser an Yoga in der eigenen
            Wohnung, Haus oder Garten zu machen. Du entscheidest, ob Du zu uns
            ins Studio kommst, oder ob Du das Studio zu Dir nach Hause holst.
            Bitte melde Dich über unser Tool an, damit wir:
          </P>
        }
        list={[
          <>
            Dir den Link schicken oder einen Platz für Deine Yogamatte
            reservieren können.
          </>,
          <>
            Nach jede Yogastunde in unserem warmen und Hellen Studio mit
            wunderschönen Sonnenuntergängen, laden wir Dich herzlich ein eine
            Tasse Tee mit uns und andere Yogis zu genießen.
          </>,
          <>
            Wichtig: nur die Yogalehrer können Dich via Zoom sehen, nicht die
            anderen Yogis und umgekehrt
          </>,
        ]}
        imgLeft={data.headstand}
      />
      <SideBlocks>
        Yoga ist für jeden, aber nicht jeden Yoga Stil, Studio oder YogaleherIn
        ist für Jeden. Deswegen raten wir Dir an immer verschiedene Yoga-Stile
        sowie Studios kennenzulernen damit Du erfährst was Du brauchst, wo und
        mit wem.
      </SideBlocks>
      <ContentAndPicture
        h2="Welche Yogastile bieten wir in unserem Studio an?"
        id="yogastile"
        description={
          <div>
            <P>
              ‚Yoga‘ kann als Überbegriff für viele Yogatraditionen gesehen
              werden. Es gibt nämlich diverse (moderne) Stilen aus Indien, aber
              auch neue aus dem Westen. Von denen bieten wir:
            </P>
            <ul>
              <Li>Ashtanga Vinyasa (Indien) </Li>
              <Li>Iyengar Yoga (Indien) </Li>
              <Li>Yin Yoga (Westen) </Li>
              <Li>Acro-Yoga (Westen) </Li>
            </ul>
            <P>
              Allen Stilen fokussieren sich auf bestimmte Kern Prinzipien, die
              sich manchmal überschneiden, die unterschiedliche Effekte auf
              Körper und Geist haben. Da wir in Indien ausgebildet sind, folgen
              wir, mit Ausnahme von Yin und Acro-Yoga, Indische Hatha Yoga
              Traditionen.
            </P>
          </div>
        }
        imgRight={data.chaturanga}
      />
      <IIIStep
        service
        ltr
        title="Unser Angebot"
        description=""
        columns="repeat(3, 1fr)"
        columnsTablet="repeat(2, 1fr)"
        columnsMobile="repeat(1, 1fr)"
        widthMobile="45vw"
        pathname={location.pathname}
        values={[
          {
            id: (
              <>
                Sanfter Yoga Flow in die Woche
                <br />
                (alle Level)
              </>
            ),
            img: data.sanfterYogaFlow,
            to: "/yoga-muenchen/#sanfter-yoga-flow",
            alt: "Sanfter Yoga Flow in die Woche (alle Level)",
          },
          {
            id: (
              <>
                Dynamischer Yoga Flow
                <br />
                (Fortgeschrittene)
              </>
            ),
            img: data.dynYogaFlow,
            to: "/yoga-muenchen/#dynamischer-yoga-flow",
            alt: "Dynamischer Yoga Flow (Fortgeschrittene)",
          },
          {
            id: (
              <>
                Yin Yoga
                <br />
                (alle Level)
              </>
            ),
            img: data.yinYoga,
            to: "/yoga-muenchen/#yin-yoga",
            alt: "Yin Yoga (alle Level)",
          },
          {
            id: <>Ashtanga</>,
            img: data.asthanga,
            to: "/yoga-muenchen/#asthanga-yoga",
            alt: "Ashtanga",
          },
          {
            id: (
              <>
                Yoga Core & Armbalance Focus <br />
                (alle Level)
              </>
            ),
            img: data.core,
            to: "/yoga-muenchen/#core-yoga",
            alt: "Yoga Core & Armbalance Focus (alle Level)",
          },
          {
            id: (
              <>
                Familien Yoga
                <br />
                (alle Level)
              </>
            ),
            img: data.family,
            to: "/yoga-muenchen/#familienyoga",
            alt: "Familien Yoga (alle Level)",
          },
        ]}
      />

      <ContentAndPicture
        id="sanfter-yoga-flow"
        h2="Sanfter Yoga Flow in die Woche"
        description={
          <P>
            In der Früh sind wir immer steifer als später am Tag da wir erst
            aufgewacht sind. Deswegen ist es wichtig langsam unseren Körper und
            Geist zu öffnen. Durch sanfte Haltungen (Asana) und Atemübungen
            (Pranayama) können wir stark, balanciert und konzentriert die Woche
            starten. Diese Stunde ist eine Mischung aus Ashtanga und Iyengar
            Yoga, das heißt, dass wir für 5 bis 10 Atemzügen in einer Haltung
            bleiben und eventuell Props, wie Gürtel, Blöcke und Bolster nutzen,
            um unseren Körper zu unterstützen und mehr aus den Haltungen holen
            zu können.
          </P>
        }
        list={[
          "Alle Level",
          "Intensität: gering – mittel",
          "Ashtanga / Iyengar Mischung",
          "Props: Gurt, Bolster, Blöcke (Optional)",
        ]}
        imgLeft={data.sanfterYogaFlow2}
      />
      <ContentAndPicture
        h2="Dynamischer Yoga Flow (Fortgeschrittene)"
        id="dynamischer-yoga-flow"
        description={
          <P>
            Diese Yogastunde ist ein aktiver und dynamischer Mix zwischen
            Ashtanga Vinyasa und Iyengar Yoga. Das bedeutet das wir kurz (5 bis
            10 Atemzüge) aber kräftig von Körperhaltung zu Körperhaltung
            fließen. Zudem tauchen wir, mit oder ohne Props (Iyengar), tiefer in
            verschiedene und herausfordernde Asanas ein. Flexibilität und Kraft
            ergeben Mobilität. Mobilität ergibt körperliche und mentale Balance.
          </P>
        }
        list={[
          "Für Fortgeschrittene",
          "Intensität: mittel – hoch ",
          "Ashtanga Vinyasa / Iyengar Mischung ",
          "Props: Gurt, Bolster, Blöcke (Optional)",
        ]}
        imgRight={data.dynYogaFlow2}
      />
      <ContentAndPicture
        h2="Yin Yoga (alle Level)"
        id="yin-yoga"
        description={
          <P>
            Yin Yoga hat Einfluss auf tief liegendes Bindegewebe wie Faszien,
            Bänder und Sehnen. Damit stimulieren wir die Struktur unseres
            Körpers. Andere aktiveren (Yang) Yogastilen wie zum Beispiel
            Ashtanga Yoga fördern vor allem Muskeln und Faszien. Wir erreichen
            diese tief liegenden Bindegewebe, indem wir auf passive Weise
            länger, etwa 3 bis 5 Minuten, in bestimmte Körperhaltungen bleiben.
            Damit wir gezielter und effektiver positiven ‚Stress‘ auf bestimmte
            Teile des Körpers legen können, nehmen wir mittels Props unnötiges
            ‚Stress‘ von anderen Körperteilen weg. Daher sind die Effekte auf
            Körper und Geist nach der Stunde anders als bei aktiveren Stilen.
          </P>
        }
        list={[
          "Alle Level ",
          "Intensität: gering",
          "3 bis 5 min in einer Körperhaltung",
          "Props: Gurt, Bolster, Blöcke (Optional)",
        ]}
        imgLeft={data.yinYoga2}
      />
      <ContentAndPicture
        h2="Ashtanga"
        id="asthanga-yoga"
        description={
          <P>
            Ashtanga Yoga ist aktiv, energievoll und synchronisiert den Atem mit
            Bewegungen (Pranayama und Asana Vinyasa). Die Stunde charakterisiert
            sich durch viele Vinyasas (reibungslose Übergänge zwischen Asanas)
            und kräftige Körperhaltungen, die Deinen Körper in allen Richtungen
            öffnet, stärkt, flexibler und damit mobiler macht. Da Ashtanga
            richtig herausfordernd ist und damit manchmal abschreckend wirkt,
            führen wir dich an Ashtanga heran, damit Du Schritt für Schritt
            Ashtanga für Dich selbst entdecken kannst.
          </P>
        }
        list={[
          "Intensität: mittel",
          "Props: Gurt, Bolster, Blöcke (Optional)",
        ]}
        imgRight={data.asthanga2}
      />
      <ContentAndPicture
        h2="Yoga Core & Armbalance Focus (alle Level)"
        id="core-yoga"
        description={
          <P>
            Bekannte Armbalance Haltungen wie der Krähe, Kopfstand und Handstand
            brauchen Core Kraft und Flexibilität in den Handgelenken, Beinen,
            Schultern, Rücken und Hüften. Diese Yogastunde unterstützt und
            begleitet Dich auf spielerische Weise Schritt für Schritt diese und
            andere Asanas zu meistern. Du wirst gut ins Schwitzen kommen und
            Dich richtig auspowern können. Obwohl die Intensität meistens mittel
            – hoch ist, kann jeder ohne Problemen mitmachen, da wir viele
            Optionen und Variationen zeigen. Es geht letztendlich nicht darum
            das Du deine Zehnen berührst, es geht darum, dass Du deinem Körper
            ein bisschen arbeiten lässt und Dich am Ende besser, gesünder und
            glücklicher fühlst.
          </P>
        }
        list={[
          "Alle Level",
          "Intensität: mittel – hoch",
          "Ashtanga / Iyengar Mischung",
          "Props: Wände, Gurt, Bolster, Blöcke (Optional)",
        ]}
        imgLeft={data.core2}
      />
      <ContentAndPicture
        h2="Familienyoga (im Sommer am Fasaneriesee)"
        id="familienyoga"
        description={
          <P>
            Starte Dein Wochenende entspannt und lächelnd mit unsere Familien
            Yogastunde am Samstag. Entdecke auf spielerische Weise die
            Grundlagen des Ashtanga, Iyengar und Acro Yogas. Beim Acro-Yoga
            kannst Du mit deinem Partner / Vater / Mutter / Kind coole (Balance)
            Asanas üben!
          </P>
        }
        list={[
          "Alle Level",
          "Während Sommermonaten bei schönem Wetter am Fasaneriesee",
          "Intensität: gering – mittel",
          "Acro-Yoga, Ashtanga / Iyengar Mischung",
          "Props: Partner (Optional), Wände, Gurt, Bolster, Blöcke (Optional)",
        ]}
        imgRight={data.family2}
      />
      <Schedule filter="Yoga" />
      <Container backgroundColor="transparent" padding="4em 0" />
      <TrustBox
        title="Unser Yoga- und Kampfsportangebot überzeugt:"
        values={[
          {
            id: 1,
            img: data.trust1,
            trust: "Kampfsportschule des Jahres 2020",
          },
          {
            id: 5,
            img: data.trust5,
            trust: "Yogalehrerausbildung in Indien von Lalit Kumar",
          },
          {
            id: 3,
            img: data.trust3,
            trust: "National und International erfolgreich",
          },
          {
            id: 6,
            img: data.trust6,
            trust: "BJJ Graduierung duch Cadu Francis",
          },
          {
            id: 4,
            img: data.trust4,
            trust: "Begeisterte Kunden",
          },
          {
            id: 2,
            img: data.trust2,
            trust: "IBJJF Zertifiziert",
          },
        ]}
      />
      <IIIStep
        title="Jede größere Änderung beginnt mit einem Schritt!"
        description="Egal, ob Du nach der Arbeit Stress abbauen, abnehmen, Dich in Form bringen oder Dich körperlich oder mental herausfordern möchtest, Team Laurien & Alex hilft Dir dabei, Dein Ziel zu erreichen."
        pathname={location.pathname}
        values={[
          {
            id: 1,
            img: data.step1,
            value: "Schritt 1 ",
            statement: "Buche Deine kostenlose Probestunde noch heute!",
          },
          {
            id: 2,
            img: data.step2,
            value: "Schritt 2",
            statement:
              "Habe Spaß beim Training mit dem Team & freue Dich auf fortlaufende Unterstützung.",
          },
          {
            id: 3,
            img: data.step3,
            value: "Schritt 3",
            statement:
              "Erfahre wie sich Deine körperliche und geistige Fitness verbessert!",
          },
        ]}
      />
      <Container backgroundColor="transparent">
        <H2 center noLine>
          Wir versprechen Dir, Du fühlst Dich jederzeit wilkommen!
        </H2>
      </Container>
      <Testimonials
        title="Das sagen unsere Mitglieder:"
        values={[
          {
            id: 4,
            review:
              "TLA gibt es nun mehr denn 2 Jahre. Mein Sohn (8 Jahre) ist seit der ersten Stunde BJJ dabei und immer noch Feuer und Flamme für diesen Kampfsport. Er hatte sich nie für irgendein Hobby interessiert, doch gleich nach der ersten kostenlosen Probestunde statt für ihn fest, das gefällt ihm, hier will er bleiben. Mittlerweile haben sich seine Skills dank der fürsorglichen und kompetenten Hinwendung durch Laurien und Alex so stark verbessert, dass er sogar auf Turnieren im Ausland Gold gewinnt. Wir, die Eltern, sind mittlerweile auch feste Mitglieder bei TLA und trainieren regelmäßig bei MMA und BJJ mit. Somit sind Laurien und Alex ein Gewinn für unsere ganze Familie geworden und wir möchten Sie nicht mehr missen!!!",
            reviewer: "Susanne R.",
            platform: "meunchen.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 1,
            review:
              "Kleines, sauberes Gym mit familiärer Atmosphäre. Laurien und Alex machen ihren Job sauber und mit Herz. Sie gehen toll mit Kindern um, haben ein super Verhältnis zu den Jugendlichen und jeder wird als Freund empfangen und nicht als zahlender Kunde gesehen. Hier stimmt das Verhältnis zwischen Härte und Herz. Fachlich und technisch sind die zwei Top und ergänzen sich gut. Die Trainings sind super und am nächsten Tag weiß man, was man getan hat 👍🏻",
            reviewer: "Sebastian R.",
            platform: "google.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 2,
            reviewer: "Nacht Glocke",
            img:
              "https://lh3.ggpht.com/-EF1fjB92uWs/AAAAAAAAAAI/AAAAAAAAAAA/6Yz6efcUG2g/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Tolle Kampfsportschule, Laurin und Alex sind mega sympathisch und sehr professionell. Die Kampfsportstunden machen richtig Spaß und Yoga ist auch super. Vor allem die Aktion im Sommer #yogaamfasaneriesee, bei der erfolgreich Geld für die Münchner Tafel gesammelt wurde. Große Empfehlung",
            platform: "google.de",
          },
          {
            id: 3,
            reviewer: "macmel 2",
            img:
              "https://lh4.ggpht.com/-BZVPNvS1X0I/AAAAAAAAAAI/AAAAAAAAAAA/VgwMgc_iE4Q/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Ein Super Gym!!! Echt tolle Leute und super Trainer mit Erfahrung, jedes mal lernt man was neues und wird im in seinen individuellen Stärcken aber auch Scheächen gestärkt. Kann man echt nur weiter empfehlen, wenn jemand ein neues Gym mit familiärer Atmosphäre sucht!",
            platform: "google.de",
          },
        ]}
      />
      <div>
        <ContactForm />
      </div>
      <Download image={data.magnet} />
    </Layout>
  )
}
export const query = graphql`
  {
    hero1: file(relativePath: { eq: "Yoga/Yoga-Twist-Yogastunde.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    hero2: file(relativePath: { eq: "Yoga/Yoga-Laurien-balance-9.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    hero3: file(
      relativePath: { eq: "Yoga/Yoga-Meditations-Fasaneriesee.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    hero4: file(relativePath: { eq: "Yoga/Yoga-Boat-Alex.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    hero5: file(
      relativePath: { eq: "Yoga/Yoga-am-Fasaneriesee-Laurien-Dehnung.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    sanfterYogaFlow: file(
      relativePath: { eq: "Yoga/Sanfter-Yoga-Flow-in-die-Woche.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    sanfterYogaFlow2: file(
      relativePath: { eq: "Yoga/Yoga-Muenchen-Nadi-Shodana.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    dynYogaFlow: file(relativePath: { eq: "Yoga/Dynamischer-Yoga-Flow.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    dynYogaFlow2: file(
      relativePath: { eq: "Yoga/Yoga-Muenchen-Dynamischer-Yoga-Flow.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    yinYoga: file(relativePath: { eq: "Yoga/Yin-Yoga.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    yinYoga2: file(relativePath: { eq: "Yoga/Yoga-Muenchen-Yin-Yoga.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    asthanga: file(relativePath: { eq: "Yoga/Ashtanga-für-Anfänger.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    asthanga2: file(relativePath: { eq: "Yoga/Yoga-Muenchen-Asthanga.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    headstand: file(relativePath: { eq: "Yoga/Yoga-Kopfstand-Laurien.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    chaturanga: file(relativePath: { eq: "Yoga/Yoga-Chaturanga-Alex.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    core: file(relativePath: { eq: "Yoga/Yoga-Core-Armbalance-Focus.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    core2: file(relativePath: { eq: "Yoga/Yoga-Muenchen-Core-Balance.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    family: file(relativePath: { eq: "Yoga/Familienyoga.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    family2: file(relativePath: { eq: "Yoga/Familien-Yoga-Muenchen.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust1: file(
      relativePath: { eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust2: file(relativePath: { eq: "IBJJF-Zerifikat-TLA-BJJ.JPG" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust3: file(
      relativePath: { eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust5: file(
      relativePath: {
        eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust4: file(relativePath: { eq: "Reviews-Team-Laurien-Alex.JPG" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust6: file(
      relativePath: {
        eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    step1: file(
      relativePath: {
        eq: "Persoenliche-Unterstuetzung-beim-kostenlosen-Probetraining-TLA-Muenchen.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    step2: file(
      relativePath: { eq: "Spass-beim-Training-im-Team-Muenchen.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    step3: file(
      relativePath: {
        eq: "Fitness-verbessern-team-laurien-alex-chaturanga-liegestuetz.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    magnet: file(
      relativePath: { eq: "Cover_Bist_du_gestresst_atemuebungen.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`
export default Yoga
