import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentAndPicture from "../components/content-and-picture"

import { HeroSimple } from "../components/hero"
import ValueBox from "../components/valuebox"
import Schedule from "../components/schedule"
import TrustBox from "../components/trusbox"
import IIIStep from "../components/3step"
import Testimonials from "../components/testimonials"
import { ContactForm, Download } from "../components/contactform"
import SideBlocks from "../components/side-blocks"
import LittleNinjas from "../components/littleNinjas"

import { Container, H2, P } from "../components/styledcomponents"

const BJJ = ({ location, data }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title=" Brazilian Jiu-Jitsu / BJJ in München"
        description=" Trainiere Brazilian Jiu-Jitsu / BJJ in unserer Kampfsportschule in München und lerne dich effektiv gegen stärkere, größere Gegner zu verteidigen!"
      />
      <HeroSimple
        title="Brazilian Jiu Jitsu - BJJ in München"
        values={[
          {
            id: 1,
            img: data.hero1,
            imgAlt: "Yoga in München",
          },
          {
            id: 2,
            img: data.hero2,
            imgAlt: "MMA - Mixed Martial Arts in München",
          },
          {
            id: 3,
            img: data.hero3,
            imgAlt: "MMA für Jugendliche in München",
          },
          {
            id: 4,
            img: data.hero4,
            imgAlt: "MMA für Kinder in München",
          },
          {
            id: 5,
            img: data.hero5,
            imgAlt: "BJJ für Kinder in München",
          },
        ]}
        color="#ffffff"
        buttoncolor="#f17915"
      />
      <ValueBox
        values={[
          {
            id: 1,
            value: "Stressabbau",
          },
          { id: 2, value: "Körperliche Fitness & Mentale Fitness" },
          { id: 3, value: "Lösungsorientiertes Denken" },
          { id: 4, value: "Team Spirit" },
        ]}
      />

      <ContentAndPicture
        h2="Was ist Brazilian Jiu Jitsu / BJJ?"
        description={
          <P>
            Brazilian Jiu Jitsu ist die Wissenschaft und Kunst der Kontrolle,
            die zur Aufgabe/Submission führt. BJJ hat sich aus dem Bodenkampf
            des Kodokan Judo, auch als Ne Waza bekannt, entwickelt. Mitsuyo
            Maeda brachte Kodokan Judo und damit Ne Waza nach Brasilien, indem
            er Carlos und Helio Gracie unterrichtete. Daraus entwickelten die
            Gracies ihren eigenen Kampfsport-Stil, welchen sie zunächst an
            Familienmitglieder und später an viele andere weltweit weitergaben.
            Daher der Name Brazilian Jiu Jitsu (BJJ).
          </P>
        }
      />

      <ContentAndPicture
        h2="Das wichtigste Schlüsselkonzept im BJJ"
        description={
          <P>
            Eine kleinere, leichtere und schwächere Person kann einen größeren,
            schwereren und stärkeren Angreifer erfolgreich überwinden. Dies ist
            durch:
            <ul>
              <li>
                den Einsatz von sauber angewandten Techniken wie
                Kontrollpositionen, Würfen, Würge- und Hebeltechniken, die den
                Angreifer zur Aufgabe zwingen (ohne Gewalt oder Schaden),
                möglich.
              </li>
              <li>Beim BJJ gibt der Gegner auf, indem er abklopft.</li>
              <li>
                BJJ ist daher eine hochwirksame Kampfkunst und damit auch
                besonders für Frauen und Kinder geeignet.
              </li>
            </ul>
          </P>
        }
        imgLeft={data.hiptoss}
      />

      <ContentAndPicture
        h2="Wie läuft ein BJJ Training ab?"
        list={[
          <>
            Wir trainieren sowohl Gi, also in der klassischen Uniform, als auch
            NoGi, also in Shorts und T-Shirt um sowohl die Verteidigung (der Gi
            bietet gute Griffe, was die Verteidigung erschwert) als auch die
            Offensive (ohne Gi ist die Kontrolle schwieriger, was den Angriff
            erschwert) zu trainieren.
          </>,
          <>
            Warming Up: Bewegungen und Übungen die sowohl Deinen Körper stärken
            als auch BJJ Techniken unterstützen
          </>,
          <>
            Technik Teil: je nach Thema übst Du verschiedene BJJ Techniken:
            Würfe, Würge- und Hebeltechniken, Sweeps, Passes usw.
          </>,
          <>
            Sparring Teil: Übungskämpfe. Dabei werden Atribute wie Kraft und
            Geschwindigkeit herausgenommen, so dass Techniken ausgetauscht
            werden. Man lernt das es nicht um Atribute geht, sondern um saubere
            Technik und Timing.
          </>,
          <>
            Yoga Cooling Down um Muskelkater und Verletzungsrisiko zu
            reduzieren.
          </>,
        ]}
        ordered
        imgRight={data.heelhook}
      />

      <ContentAndPicture
        h2="BJJ für Jugendliche und Erwachsene"
        id="jugendliche-und-erwachsene"
        description={
          <P>
            BJJ ist wie Blitzschach – Du lernst während Du mit Deinem Partner
            trainierst gleichzeitig Problemlösend zu denken und zu handeln.
            Deswegen verbessert BJJ Deine körperliche und mentale Fitness
            schnell. Zudem ist BJJ der perfekte Ausgleich zum Alltag, da Du in
            einer sicheren und freundschaftlichen Atmosphäre trainierst und Dich
            auspowerst.
            <br />
            BJJ Training:
          </P>
        }
        list={[
          "Steigert körperliche & mentale Fitness",
          "Baut Stress ab",
          "Macht Dich gesünder und fitter",
          "Steigert Selbstbewusstsein",
          "Teamspirit: werde Teil des Teams und schließe neue Freundschaften"
        ]}
        imgLeft={data.triangle}
      />

      <ContentAndPicture
        h2="BJJ für Kinder"
        id="kinder"
        description={
          <P>
            BJJ eignet sich sehr gut für Kinder, da es keine Schläge und Tritte
            gibt. Die Kinder lernen den eigenen Körper und den Körper des
            Partners in einer freundschaftlichen und sicheren Umgebung zu
            kontrollieren und dementsprechend die Situation zu deeskalieren (auf
            den Schulhof Verbal und zur Not auch körperlich).
            <br />
            BJJ Kids Training:
          </P>
        }
        list={[
          "Setzt Mobbing ein Ende",
          "Steigert das Selbstvertrauen",
          "Verbessert die Körperhaltung",
          "Möglichkeit an Wettkämpfen teilzunehmen",
          "Fordert auf spielerische Weise Disziplin und Konzentration",
          "Verbessert körperliche und geistige Kontrolle",
          "Kinder lernen wie sich Verbal – und zur Not auch körperlich – zu verteidigen",
        ]}
        imgRight={data.kids}
      />

      <LittleNinjas id="ninjas" />

      <SideBlocks>
        Fördert Konzentration und Disziplin | Stärkt den Charakter und
        verbessert das Selbstbewusstsein | Ermöglicht sich richtig auszupowern |
        Eine verbesserte mentale Fitness | lösungsorientiertes Denken | Umgang
        mit Stress
      </SideBlocks>
      <Schedule filter="BJJ" />
      <Container backgroundColor="transparent" padding="4em 0" />
      <TrustBox
        title="Unser Yoga- und Kampfsportangebot überzeugt:"
        values={[
          {
            id: 1,
            img: data.trust1,
            trust: "Kampfsportschule des Jahres 2020",
          },
          {
            id: 5,
            img: data.trust5,
            trust: "Yogalehrerausbildung in Indien von Lalit Kumar",
          },
          {
            id: 3,
            img: data.trust3,
            trust: "National und International erfolgreich",
          },
          {
            id: 6,
            img: data.trust6,
            trust: "BJJ Graduierung duch Cadu Francis",
          },
          {
            id: 4,
            img: data.trust4,
            trust: "Begeisterte Kunden",
          },
          {
            id: 2,
            img: data.trust2,
            trust: "IBJJF Zertifiziert",
          },
        ]}
      />
      <IIIStep
        title="Jede größere Änderung beginnt mit einem Schritt!"
        description="Egal, ob Du nach der Arbeit Stress abbauen, abnehmen, Dich in Form bringen oder Dich körperlich oder mental herausfordern möchtest, Team Laurien & Alex hilft Dir dabei, Dein Ziel zu erreichen."
        pathname={location.pathname}
        values={[
          {
            id: 1,
            img: data.step1,
            value: "Schritt 1 ",
            statement: "Buche Deine kostenlose Probestunde noch heute!",
          },
          {
            id: 2,
            img: data.step2,
            value: "Schritt 2",
            statement:
              "Habe Spaß beim Training mit dem Team & freue Dich auf fortlaufende Unterstützung.",
          },
          {
            id: 3,
            img: data.step3,
            value: "Schritt 3",
            statement:
              "Erfahre wie sich Deine körperliche und geistige Fitness verbessert!",
          },
        ]}
      />
      <Container backgroundColor="transparent">
        <H2 center noLine>
          Wir versprechen Dir, Du fühlst Dich jederzeit wilkommen!
        </H2>
      </Container>
      <Testimonials
        title="Das sagen unsere Mitglieder:"
        values={[
          {
            id: 4,
            review:
              "TLA gibt es nun mehr denn 2 Jahre. Mein Sohn (8 Jahre) ist seit der ersten Stunde BJJ dabei und immer noch Feuer und Flamme für diesen Kampfsport. Er hatte sich nie für irgendein Hobby interessiert, doch gleich nach der ersten kostenlosen Probestunde statt für ihn fest, das gefällt ihm, hier will er bleiben. Mittlerweile haben sich seine Skills dank der fürsorglichen und kompetenten Hinwendung durch Laurien und Alex so stark verbessert, dass er sogar auf Turnieren im Ausland Gold gewinnt. Wir, die Eltern, sind mittlerweile auch feste Mitglieder bei TLA und trainieren regelmäßig bei MMA und BJJ mit. Somit sind Laurien und Alex ein Gewinn für unsere ganze Familie geworden und wir möchten Sie nicht mehr missen!!!",
            reviewer: "Susanne R.",
            platform: "meunchen.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 1,
            review:
              "Kleines, sauberes Gym mit familiärer Atmosphäre. Laurien und Alex machen ihren Job sauber und mit Herz. Sie gehen toll mit Kindern um, haben ein super Verhältnis zu den Jugendlichen und jeder wird als Freund empfangen und nicht als zahlender Kunde gesehen. Hier stimmt das Verhältnis zwischen Härte und Herz. Fachlich und technisch sind die zwei Top und ergänzen sich gut. Die Trainings sind super und am nächsten Tag weiß man, was man getan hat 👍🏻",
            reviewer: "Sebastian R.",
            platform: "google.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 2,
            reviewer: "Nacht Glocke",
            img:
              "https://lh3.ggpht.com/-EF1fjB92uWs/AAAAAAAAAAI/AAAAAAAAAAA/6Yz6efcUG2g/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Tolle Kampfsportschule, Laurin und Alex sind mega sympathisch und sehr professionell. Die Kampfsportstunden machen richtig Spaß und Yoga ist auch super. Vor allem die Aktion im Sommer #yogaamfasaneriesee, bei der erfolgreich Geld für die Münchner Tafel gesammelt wurde. Große Empfehlung",
            platform: "google.de",
          },
          {
            id: 3,
            reviewer: "macmel 2",
            img:
              "https://lh4.ggpht.com/-BZVPNvS1X0I/AAAAAAAAAAI/AAAAAAAAAAA/VgwMgc_iE4Q/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Ein Super Gym!!! Echt tolle Leute und super Trainer mit Erfahrung, jedes mal lernt man was neues und wird im in seinen individuellen Stärcken aber auch Scheächen gestärkt. Kann man echt nur weiter empfehlen, wenn jemand ein neues Gym mit familiärer Atmosphäre sucht!",
            platform: "google.de",
          },
        ]}
      />
      <div>
        <ContactForm />
      </div>
      <Download image={data.magnet} />
    </Layout>
  )
}
export const query = graphql`{
  hero1: file(relativePath: {eq: "BJJ/BJJ-Muenchen-Overhead-Sweep.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero2: file(relativePath: {eq: "BJJ/BJJ-Kids-Muenchen-Passing-Guard.JPG"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero3: file(relativePath: {eq: "BJJ/BJJ-Muenchen-Heelhook-footlock.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero4: file(relativePath: {eq: "BJJ/BJJ-Kids-Muenchen-Double-Leg-Takedown.JPG"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero5: file(relativePath: {eq: "BJJ/BJJ-Muenchen-Guard-Pass.JPG"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hiptoss: file(relativePath: {eq: "BJJ/BJJ-Muenchen-Hueftwurf.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  heelhook: file(relativePath: {eq: "BJJ/BJJ-Muenchen-Footlock-Heelhook.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  triangle: file(relativePath: {eq: "BJJ/BJJ-Muenchen-Triangle-Wuerger.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  kids: file(relativePath: {eq: "BJJ/BJJ_Kids-knee-slide-pass.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust1: file(
    relativePath: {eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust2: file(relativePath: {eq: "IBJJF-Zerifikat-TLA-BJJ.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust3: file(relativePath: {eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust5: file(
    relativePath: {eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust4: file(relativePath: {eq: "Reviews-Team-Laurien-Alex.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust6: file(
    relativePath: {eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step1: file(
    relativePath: {eq: "Persoenliche-Unterstuetzung-beim-kostenlosen-Probetraining-TLA-Muenchen.JPG"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step2: file(relativePath: {eq: "Spass-beim-Training-im-Team-Muenchen.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step3: file(
    relativePath: {eq: "Fitness-verbessern-team-laurien-alex-chaturanga-liegestuetz.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  magnet: file(relativePath: {eq: "Cover_Bist_du_gestresst_atemuebungen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`
export default BJJ
